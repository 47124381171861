import {
    Mesh,
    PerspectiveCamera,
    Scene,
    Vector3,
    WebGLRenderer,
    WebGLRenderTarget,
    PlaneGeometry,
    Vector2,
    Math as _Math,
    ShaderMaterial,
    CubeTextureLoader,
  Color,
    TextureLoader,
} from 'three'

import { Easing } from 'es6-tween'
import GLTFLoader from 'three-gltf-loader'
import polygonModel from './polygon.glb'
import polygonVert from './polygon.vert'
import polygonFrag from './polygon.frag'
import fluidVert from './fluid.vert'
import fluidFrag from './fluid.frag'
import bgVert from './bg.vert'
import bgFrag from './bg.frag'
import bgImg from './logo.jpg'
import side0 from './cube/side0.jpg'
import side1 from './cube/side1.jpg'
import side2 from './cube/side2.jpg'
import side3 from './cube/side3.jpg'
import side4 from './cube/side4.jpg'
import side5 from './cube/side5.jpg'

const DELAY = 0.1
const SPEED = {ratio: 2}

class Logo {

  constructor(container) {
        this.onMove = this.onMove.bind(this)
        this.resize = this.resize.bind(this)
        this.update = this.update.bind(this)

        this.isLoaded = false
        this.isRunning = false

        this.container = container
        const canvas = container.querySelector('canvas')
        this.scene = new Scene()
        this.renderTargetScene = new Scene()


        this.polygon = null
        this.polygonBaseRotation = new Vector3(Math.random(), Math.random(), Math.random())

        const {width, height} = this.container.getBoundingClientRect()
        this.width = width
        this.height = height
        this.minSize = Math.min(width, height)
        this.isLandscape = this.width >= this.height

        this.mouse = new Vector2(width * 0.5, height * 0.5)
        this.delay = this.mouse.clone()
        this.offset = new Vector2(0.5, 0.5)

        this.renderer = new WebGLRenderer({antialias: true, canvas: canvas})
        this.renderer.setPixelRatio(window.devicePixelRatio)

        this.camera = new PerspectiveCamera(50, 1, 1, 5000)
        this.renderTargetCam = new PerspectiveCamera(50, 1, 1, 5000)

        this.camera.position.set(0, 0, 250)
        this.camera.lookAt(new Vector3())
        this.renderTargetCam.position.set(0, 0, 250)
        this.renderTargetCam.lookAt(new Vector3())

        this.scene.add(this.camera)
        this.renderTargetScene.add(this.renderTargetCam)

        this.renderTarget = new WebGLRenderTarget()
    }

    load() {
      if (this.isLoaded) {
        return Promise.resolve()
      }

      return Promise.all([this.loadModel(), this.loadBg(), this.loadCube()])
            .then(results => {
                const [polyFile, bgTex, cubeTex] = results

                this.bgMaterial = new ShaderMaterial({
                    uniforms: {
                        color: {value: new Color(1, 1, 1)},
                        bgTexture: {value: bgTex},
                        screen: {value: new Vector2()}
                    },
                    vertexShader: bgVert,
                    fragmentShader: bgFrag,
                    depthWrite: false,
                    depthTest: false
                })
                this.scene.add(new Mesh(new PlaneGeometry(2, 2), this.bgMaterial))

                this.fluidMaterial = new ShaderMaterial({
                    extensions: {derivatives: true},
                    uniforms: {
                        time: {value: 0},
                      offset: { value: 1.5 },
                        strength: {value: 10},
                        bgTexture: {value: bgTex},
                        fresnelBias: {value: 0.0},
                        fresnelScale: {value: 0.5},
                        fresnelPower: {value: 15},
                        refractionRatio: {value: 0.5},
                        refractionStrength: {value: 0.05},
                        reflectionStrength: {value: 0.5},
                        fresnelStrength: {value: 0.5},
                        screen: {value: new Vector2()},
                    },
                    vertexShader: fluidVert,
                    fragmentShader: fluidFrag,
                    transparent: true,
                })

              this.bubble = new Mesh(new PlaneGeometry(40, 30, 120, 90), this.fluidMaterial)
                this.bubble.position.set(0, 0, -35)
                this.renderTargetCam.add(this.bubble)

                this.polygonMaterial = new ShaderMaterial({
                    uniforms: {
                      fade: { value: 1 },
                        fresnelBias: {value: 0.05},
                        fresnelScale: {value: 0.45},
                        fresnelPower: {value: 3},
                        bgTexture: {value: this.renderTarget.texture},
                        envMap: {value: cubeTex},
                        screen: {value: new Vector2()},
                        refractionRatio: {value: 0.1},
                        reflectionStrength: {value: 1},
                        refractionStrength: {value: 0.1},
                    },
                    vertexShader: polygonVert,
                    fragmentShader: polygonFrag,
                })


                this.scene.add(polyFile.scene)
                polyFile.scene.traverse(obj => {
                    if (obj.isMesh) {
                        this.polygon = obj
                        this.polygon.material = this.polygonMaterial
                    }
                })

                this.isLoaded = true
            })
    }

    start() {
      return this.load().then(() => {
            window.addEventListener('mousemove', this.onMove)
            window.addEventListener('touchmove', this.onMove)
        window.addEventListener('resize', this.resize)
        this.resize()
        this.raf = requestAnimationFrame(this.update)
      })
    }

    stop() {
        cancelAnimationFrame(this.raf)
        window.removeEventListener('resize', this.resize)
        window.removeEventListener('mousemove', this.onMove)
        window.removeEventListener('touchmove', this.onMove)
    }

    loadModel() {
      return new Promise((resolve) => new GLTFLoader().load(polygonModel, file => resolve(file)))
    }

    loadBg() {
      return new Promise((resolve) => new TextureLoader().load(bgImg, tex => resolve(tex)))
    }

    loadCube() {
      return new Promise((resolve) => new CubeTextureLoader().load([side0, side1, side2, side3, side4, side5], tex => resolve(tex)))
    }


    onMove(event) {
        const e = event.touches ? event.touches[0] : event
        this.mouse.x = e.clientX
        this.mouse.y = e.clientY
    }

    resize() {
        const {width, height} = this.container.getBoundingClientRect()
        this.width = width
        this.height = height
        this.minSize = Math.min(width, height)
        this.isLandscape = this.width >= this.height

        const dpr = window.devicePixelRatio
        this.fluidMaterial.uniforms.screen.value.set(width * dpr, height * dpr)
        this.bgMaterial.uniforms.screen.value.set(width * dpr, height * dpr)
        this.polygonMaterial.uniforms.screen.value.set(width * dpr, height * dpr)
        this.renderTarget.setSize(width * dpr, height * dpr)
        this.renderer.setSize(width, height)
        this.camera.aspect = width / height
        this.camera.updateProjectionMatrix()
      this.renderTargetCam.aspect = width / height
      this.renderTargetCam.updateProjectionMatrix()
    }

    update(time) {
        this.raf = requestAnimationFrame(this.update)

        this.renderer.render(this.renderTargetScene, this.renderTargetCam, this.renderTarget, true)
        this.renderer.render(this.scene, this.camera)

        const deltaX = this.delay.x - this.mouse.x
        const deltaY = this.delay.y - this.mouse.y

        if (Math.abs(deltaX) > 10) {
            this.delay.x -= deltaX * DELAY
        }

        if (Math.abs(deltaY) > 10) {
            this.delay.y -= deltaY * DELAY
        }

        this.offset.set(this.delay.x / this.width, this.delay.y / this.height)

        const diffX = this.isLandscape ? _Math.clamp((this.delay.x - this.minSize) + this.minSize * 0.5, 0, this.minSize) : this.delay.x
        const ratioX = diffX / this.minSize
        const leftRatio = _Math.clamp(ratioX * 2, 0, 1)
        const rightRatio = _Math.clamp((ratioX - 0.5) * 2, 0, 1)
        const fluidRatio = Easing.Sinusoidal.In(rightRatio)
        const posX = (1 - leftRatio) * -85 + rightRatio * 10

        const sin = Math.sin(time * 0.00005 * SPEED.ratio)
        const cos = Math.cos(time * 0.00005 * SPEED.ratio)

        const scale = (3.5 + leftRatio) + Easing.Sinusoidal.In(fluidRatio) * 8
        this.polygon.scale.set(scale, scale, scale)
        this.polygon.position.set(posX, 0, 0)
        this.polygon.rotation.x = this.polygonBaseRotation.x + this.offset.y * 3 + this.offset.x * 2 - sin
        this.polygon.rotation.y = this.polygonBaseRotation.y + this.offset.y * 3 + this.offset.x * 2 - cos
        this.polygonMaterial.uniforms.reflectionStrength.value = 1 - fluidRatio
        this.polygonMaterial.uniforms.refractionStrength.value = (1 - fluidRatio) * 0.1

        this.fluidMaterial.uniforms.time.value = time
        this.fluidMaterial.uniforms.refractionRatio.value = fluidRatio * 0.1
        this.fluidMaterial.uniforms.strength.value = fluidRatio * 10.0
    }
}

export default Logo