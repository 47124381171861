import React, {Component} from 'react'

const AppContext = React.createContext()

export class AppProvider extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showCursor: type => this.setState({cursorType: type, isCursorVisible: true}),
            hideCursor: () => this.setState({isCursorVisible: false}),
            isCursorVisible: false,
            cursorType: '',
        }
    }

    render() {
        const {children} = this.props

        return (
            <AppContext.Provider value={this.state}>
                {children}
            </AppContext.Provider>
        )
    }
}

export default AppProvider
export const AppConsumer = AppContext.Consumer