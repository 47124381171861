import React, {PureComponent} from 'react'
import {autoPlay} from 'es6-tween'
import Intro from '../intro/Intro'
import styles from './Wrapper.module.scss'
import {AppConsumer} from '../../AppProvider'
import Helmet from 'react-helmet'

class Wrapper extends PureComponent {

    componentDidMount() {
        autoPlay(true)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.props.hideCursor()
        }
    }

    render() {
        const {children, location, locale, hideCursor} = this.props
        const introActive = location.pathname.replace(`/${locale}`, '') === '/'

        return (
            <div onMouseLeave={() => hideCursor()}>
                <Helmet>
                    <meta name="format-detection" content="telephone=no"/>
                    <title>Aquilas</title>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-134858139-1"/>
                    <script>
                        {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'UA-134858139-1');
                        `}
                    </script>
                </Helmet>
                <Intro isActive={introActive}/>
                <div className={styles.Content}>
                    {children}
                </div>
            </div>
        )
    }
}

const WrappedElement = props => (
    <AppConsumer>
        {({hideCursor}) => <Wrapper {...props} hideCursor={hideCursor}/>}
    </AppConsumer>
)

export default WrappedElement