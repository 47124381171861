import React from 'react'
import Wrapper from './src/components/wrapper/Wrapper'
import banner from 'banner'
import smoothscroll from 'smoothscroll-polyfill'
import AppProvider from './src/AppProvider'

eval(banner()) // eslint-disable-line no-eval
smoothscroll.polyfill()

export const wrapPageElement = ({element, props}) => {
    return (
        <AppProvider>
            <Wrapper{...props}
                    location={props.location}
                    locale={props.pageContext.locale}>
                {element}
            </Wrapper>
        </AppProvider>
    )
}
