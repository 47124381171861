import React, {PureComponent} from 'react'
import Logo from './Logo'
import styles from './Intro.module.scss'
import cx from 'classnames'

class Intro extends PureComponent {

  constructor() {
    super()
    this.state = {
      isLoaded: false,
    }
  }

    componentDidMount() {
      this.props.isActive ? this.start() : this.stop()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isActive !== this.props.isActive) {
          this.props.isActive ? this.start() : this.stop()
        }
    }

  start() {
    if (!this.logo) this.logo = new Logo(this.node)
    this.logo.start().then(() => this.setState({ isLoaded: true }))
  }

  stop() {
    if (!this.logo) this.logo = new Logo(this.node)
    this.logo.stop()
    }

    render() {
      const { isLoaded } = this.state
      const { isActive } = this.props

        return (
          <div className={cx(styles.Main, { [styles.IsVisible]: isActive })} ref={node => this.node = node}>
            <canvas className={cx(styles.Canvas, { [styles.IsVisible]: isLoaded })}/>
            </div>
        )
    }
}


export default Intro